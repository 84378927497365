<template>
  <div>
    <div :class="{ 'card card-general': fromView !== 'add_invoice' }">
      <div
        :class="{
          'card-body': fromView !== 'add_invoice',
          'pe-5 ps-5': fromView == 'add_invoice',
        }"
      >
        <div class="d-flex justify-content-end mb-2">
          <button class="btn btn-white">Item No: {{ item_form.item_no }}</button>
        </div>
        <form class="form-v2 h-100 d-flex flex-column justify-content-between">
          <div class="w-100">
            <div class="row mb-2">
              <div class="form-check form-switch col-6">
                <input
                  class="form-check-input parts"
                  type="radio"
                  id="parts"
                  value="parts"
                  v-model="item_form.service_type"
                />
                <label
                  class="form-check-label"
                  :for="item_form.service_type === 'parts' ? 'parts' : 'services'"
                  >Parts</label
                >
              </div>
              <div class="form-check form-switch ms-5 col-6">
                <input
                  class="form-check-input services"
                  type="radio"
                  id="services"
                  value="services"
                  v-model="item_form.service_type"
                />
                <label
                  class="form-check-label"
                  :for="item_form.service_type === 'services' ? 'services' : 'parts'"
                  >Services</label
                >
              </div>
            </div>

            <div class="row mb-3">
              <div class="input-group p-0">
                <div class="input-group-text prepend">
                  <img src="@/assets/images/system/network_laurenGreen.png" />
                </div>
                <select
                  name="catName"
                  class="form-select"
                  v-model="item_form.category_id"
                >
                  <option value="" disabled selected="selected">
                    Select Category (default)*
                  </option>
                  <option
                    v-for="itemCat in categoryOptions"
                    :key="itemCat.id"
                    :value="itemCat.id"
                  >
                    {{ itemCat.type }}
                  </option>
                </select>
                <div class="input-group-text transparent p-0 append">
                  <button
                    type="button"
                    name="btn"
                    class="btn btn-lauren-green _sm"
                    @click="dialogVisibleCategoryPopup = true"
                  >
                    <i class="fa fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="input-group p-0">
                <div class="input-group-text prepend">
                  <img src="@/assets/images/system/tools_lauren-green.svg" />
                </div>
                <input
                  type="text"
                  name="itemTitle"
                  class="form-control"
                  v-model="item_form.name"
                  placeholder="Type item name or title*"
                />
                <div class="input-group-text transparent p-0 append">
                  <button
                    type="button"
                    name="btn"
                    class="btn btn-white _sm"
                    @click="$emit('show-list-of-items')"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>

            <div class="row mb-4">
              <textarea
                class="form-control"
                v-model="item_form.description"
                placeholder="Parts or Services description"
              ></textarea>
            </div>
          </div>

          <div class="w-100">
            <div class="row m-0 mb-3 d-flex justify-content-end">
              <div class="col-sm-9">
                <div class="row mb-3">
                  <div class="input-group group-half p-0">
                    <div class="input-group-text bg-light-orange prepend no-after">
                      Quantity
                    </div>
                    <div class="form-floating">
                      <label class="floating-label _sm _end">(default=1)</label>
                      <input
                        type="number"
                        class="form-control text-right"
                        @keyup="calculateAmount"
                        v-model.number="item_form.quantity"
                        min="1"
                      />
                    </div>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="input-group group-half p-0">
                    <div class="input-group-text bg-light-sky no-after prepend">Rate</div>
                    <div class="form-floating">
                      <label class="floating-label">&pound;</label>
                      <input
                        type="number"
                        class="form-control text-right"
                        v-model.number="item_form.unit_price"
                        @keyup="calculateAmount"
                        step="0.01"
                        min="0.00"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="input-group group-half p-0">
                    <div class="input-group-text bg-orange no-after prepend">Amount</div>
                    <div class="form-floating">
                      <label class="floating-label">&pound;</label>
                      <input
                        type="number"
                        class="form-control text-right"
                        v-model="item_form.amount"
                        step="0.01"
                        min="0.00"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row m-0 d-flex justify-content-end">
          <div class="cmn-aw p-0">
            <button
              type="submit"
              name="save"
              class="btn btn-success btn-big _3d-input"
              @click="createItem"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog v-model="dialogVisibleCategoryPopup" title="Category" width="40%">
      <addCategory></addCategory>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisibleCategoryPopup = false">Close</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import AddCategory from "./Category.vue";

export default {
  name: "AddItem",
  components: { AddCategory },
  props: ["fromView"],
  data() {
    return {
      item_form: {
        service_type: "parts",
        name: "",
        description: "",
        quantity: 1,
        unit_price: 0,
        amount: 0,
        item_no: "",
        category_id: "",
      },
      category: "",
      dialogVisibleCategoryPopup: false,
    };
  },
  created() {
    this.generateInvoiceNumber();
    this.fetchCategory();
    this.$store.commit('item/clearToRecentItems');
  },
  computed: {
    categoryOptions() {
      if (this.category) {
        return this.category.map((v) => {
          return {
            id: v.id,
            type: v.type,
          };
        });
      }
      return [];
    },
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    calculateAmount() {
      this.item_form.amount =
        this.item_form.quantity * this.item_form.unit_price.toFixed(2);
    },
    generateInvoiceNumber() {
      this.showLoading();
      if (localStorage.getItem("item_number_generated")) {
        this.item_form.item_no = localStorage.getItem("item_number_generated");
        this.hideLoading();
        return false;
      }
      this.$store
        .dispatch("item/generateItemNumber", this.form)
        .then(() => {
          this.item_form.item_no = localStorage.getItem("item_number_generated");
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    createItem() {
      this.showLoading();
      this.$store
        .dispatch("item/createItem", this.item_form)
        .then(() => {
          this.$store.dispatch("item/getList", this.item_form.item_no);

          localStorage.removeItem("item_number_generated");
          this.$toast.success("Item created successfully.");
          this.resetForm();
          this.generateInvoiceNumber();
          if (this.fromView == "add_invoice") {
            this.$store.dispatch("item/getList", this.item_form.item_no).then(() => {
              this.$store.commit(
                "item/item_recently_added_items_invoice",
                this.item_form.item_no
              );
            });
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    resetForm() {
      this.item_form = {
        service_type: "parts",
        name: "",
        description: "",
        quantity: 1,
        unit_price: 0,
        amount: 0,
        item_no: "",
        category_id: "",
      };
    },
    fetchCategory() {
      this.$store
        .dispatch("item/fetchCategory", this.item_form)
        .then((res) => {
          this.category = res;
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {});
    },
    setAddedItemNumber() {
      this.listAddedItemNumber =
        JSON.parse(localStorage.getItem("item_recently_added_items")) || [];
    },
  },
};
</script>
