import store from '../store';

export default function authMiddleware({next, router}) {
    const isLoggedIn = store.getters['auth/getLoggedIn'];
  
    // Check if the user is logged in
    if (!isLoggedIn) {
        return router.push({name: 'login'});
    }

    // Allow the user to continue with the current route
    return next();
}
