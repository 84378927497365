<template>
  <el-menu mode="vertical" :default-active="activeIndex" :style="{height: menuHeight}"
           active-text-color="#ffd04b"
           background-color="#435b60"
           text-color="#fff"
           :router="true"
           :collapse="isCollapse">

    <el-sub-menu index="1">
      <template #title>
        <el-icon><i class="far fa-dot-circle"></i></el-icon>
        <span>Quotation</span>
      </template>
      <el-menu-item-group title="">
        <el-menu-item index="/add-quotation"><i class="far fa-plus-square"></i>&nbsp;New</el-menu-item>
        <el-menu-item index="/list-quotation"><i class="far fa-caret-square-right"></i>&nbsp;View Log</el-menu-item>
      </el-menu-item-group>
    </el-sub-menu>
    <el-sub-menu index="2">
      <template #title>
        <el-icon><i class="far fa-dot-circle"></i></el-icon>
        <span>Invoice</span>
      </template>
      <el-menu-item-group title="">
        <el-menu-item index="/add-invoice"><i class="far fa-plus-square"></i>&nbsp;New</el-menu-item>
        <el-menu-item index="/list-invoice"><i class="far fa-caret-square-right"></i>&nbsp;View Log</el-menu-item>
      </el-menu-item-group>
    </el-sub-menu>
    <el-sub-menu index="3">
      <template #title>
        <el-icon><i class="far fa-dot-circle"></i></el-icon>
        <span>Expense</span>
      </template>
      <el-menu-item-group title="">
        <el-menu-item index="/add-expense"><i class="far fa-plus-square"></i>&nbsp;New</el-menu-item>
        <el-menu-item index="/list-expenses"><i class="far fa-caret-square-right"></i>&nbsp;View Log</el-menu-item>
      </el-menu-item-group>
    </el-sub-menu>
    <el-menu-item index="/item-list">
      <el-icon><i class="fas fa-tools"></i></el-icon>
      <template #title>&nbsp; Item List</template>
    </el-menu-item>
    <el-menu-item index="/list-clients">
      <el-icon><i class="fas fa-users"></i></el-icon>
      <template #title>&nbsp; Client List</template>
    </el-menu-item>
    <el-menu-item index="6">
      <el-icon><i class="fas fa-calculator"></i></el-icon> &nbsp;
      <span>Accountant &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
    </el-menu-item>
    <hr/>
    <el-menu-item index="/setting">
      <el-icon><i class="fas fa-tools"></i></el-icon>
      <template #title>&nbsp; Setting</template>
    </el-menu-item>
    <el-menu-item index="5">
      <el-icon><i class="fas fa-users"></i></el-icon>
      <template #title>&nbsp; Subscribe</template>
    </el-menu-item>
    <el-menu-item index="6">
      <el-icon><i class="fas fa-calculator"></i></el-icon
      >
      <template #title>&nbsp; Download</template>
    </el-menu-item>
    <hr/>
    <el-menu-item index="6">
      <img src="@/assets/images/system/medal.png"/>&nbsp;
      <template #title>Rate Us</template>
    </el-menu-item>
  </el-menu>
</template>
<script>
export default {
  name: "AsideMenu",
  data() {
    return {
      loadingInstance: null,
      isCollapse: false,
      menuHeight: `${window.innerHeight - 58}px`,
      activeIndex: ''
    };
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize() {
      this.menuHeight = `${window.innerHeight - 58}px`
    },
    collapseToggle(){
      this.isCollapse = !this.isCollapse;
    }
  },
};
</script>

<style scoped>
</style>