import { nextTick } from "vue";
import { createWebHistory, createRouter } from "vue-router";
import Login from "../components/auth/Login.vue";
import ForgotPassword from "../components/auth/ForgotPassword.vue";
import Register from "../components/auth/Register.vue";
import Regional from "../components/auth/Regional.vue";
import UserDashboard from "../components/user/Dashboard.vue";
import AuthMiddleware from "../middleware/auth.middleware.js";
import LandingPage from "../components/Landing.vue";
import SoleTraderLimitedCompany from "../components/user/SoleTraderLimitedCompany.vue";
import SoleTrader from "../components/user/SoleTrader.vue";
import LimitedCompany from "../components/user/LimitedCompany.vue";
import ItemList from "../components/items/List.vue";
import AddListItem from "../components/items/AddListItem.vue";
import EditItem from "../components/items/EditItem.vue";
import PasswordConfirmation from "../components/auth/PasswordConfirmation.vue";
import Logout from "../components/auth/Logout.vue";
import store from "../store";
import ListExpense from "../components/expense/ExpenseComponent.vue";
import AddExpense from "../components/expense/AddExpenseComponent.vue";
import EditExpense from "../components/expense/EditExpenseComponent.vue";
import ListClient from "../components/clients/ListClient.vue";
import AddClient from "../components/clients/AddClient.vue";
import ListQuotation from "../components/quotation/QuotationComponent.vue";
import AddQuotation from "../components/quotation/AddQuotation.vue";
import EditQuotation from "../components/quotation/EditQuotation.vue";
import ListInvoice from "../components/invoice/ListInvoice.vue";
import AddInvoice from "../components/invoice/AddInvoice.vue";
import EditInvoice from "../components/invoice/EditInvoice.vue";
import ShowInvoice from "../components/invoice/ShowInvoice.vue";
import ShowQuotation from "../components/quotation/ShowQuotation.vue";
import SettingComponent from "../components/settings/Setting.vue";
import SuccessStripe from "../components/stripe/Success.vue";
import ErrorStripe from "../components/stripe/Error.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: LandingPage,
    meta: { layout: "login", title: "Dashboard" },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { layout: "login", title: "Login" },
  },
  {
    path: "/register",
    name: "register",
    component: Register,
    meta: { layout: "login", title: "Register" },
  },
  {
    path: "/password-confirmation",
    name: "password-confirmation",
    component: PasswordConfirmation,
    meta: { layout: "login", title: "Password Confirmation" },
  },
  {
    path: "/regional-settings",
    name: "regional-settings",
    component: Regional,
    meta: { layout: "login", title: "Regional Settings" },
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPassword,
    meta: { layout: "login", title: "Forgot Password" },
  },
  {
    path: "/dashboard",
    name: "user-dashboard",
    component: UserDashboard,
    meta: {
      middleware: AuthMiddleware,
      layout: "user",
      title: "User Dashboard",
    },
  },
  {
    path: "/item-list",
    name: "item-list",
    component: ItemList,
    meta: { middleware: AuthMiddleware, layout: "user", title: "Item List" },
  },
  {
    path: "/add-item",
    name: "add-item",
    component: AddListItem,
    meta: { middleware: AuthMiddleware, layout: "user", title: "Add Item" },
  },
  {
    path: "/edit-item/:id",
    name: "edit-item",
    component: EditItem,
    meta: { middleware: AuthMiddleware, layout: "user", title: "Edit Item" },
  },
  {
    path: "/complete-profile",
    name: "soletrader-limitedcompany",
    component: SoleTraderLimitedCompany,
    meta: {
      middleware: AuthMiddleware,
      layout: "login",
      title: "Complete Profile",
    },
  },
  {
    path: "/limited-company",
    name: "limited-company",
    component: LimitedCompany,
    meta: {
      middleware: AuthMiddleware,
      layout: "login",
      title: "Limited Company",
    },
  },
  {
    path: "/sole-trader",
    name: "sole-trader",
    component: SoleTrader,
    meta: { middleware: AuthMiddleware, layout: "login", title: "Sole Trader" },
  },
  { path: "/logout", name: "logout", component: Logout },
  {
    path: "/list-expenses",
    name: "list-expenses",
    component: ListExpense,
    meta: {
      middleware: AuthMiddleware,
      layout: "user",
      title: "List Expenses",
    },
  },
  {
    path: "/add-expense",
    name: "add-expense",
    component: AddExpense,
    meta: { middleware: AuthMiddleware, layout: "user", title: "Add Expense" },
  },
  {
    path: "/edit-expense/:id",
    name: "edit-expense",
    component: EditExpense,
    meta: { middleware: AuthMiddleware, layout: "user", title: "Edit Expense" },
  },
  {
    path: "/list-clients",
    name: "list-clients",
    component: ListClient,
    meta: { middleware: AuthMiddleware, layout: "user", title: "List Clients" },
  },
  {
    path: "/add-client",
    name: "add-client",
    component: AddClient,
    meta: { middleware: AuthMiddleware, layout: "user", title: "Add Client" },
  },
  {
    path: "/list-quotation",
    name: "list-quotation",
    component: ListQuotation,
    meta: {
      middleware: AuthMiddleware,
      layout: "user",
      title: "List Quotation",
    },
  },
  {
    path: "/add-quotation",
    name: "add-quotation",
    component: AddQuotation,
    meta: {
      middleware: AuthMiddleware,
      layout: "user",
      title: "Add Quotation",
    },
  },
  {
    path: "/show-quotation",
    name: "show-quotation",
    component: ShowQuotation,
    meta: {
      middleware: AuthMiddleware,
      layout: "user",
      title: "Show Quotation",
    },
  },
  {
    path: "/edit-quotation/:id",
    name: "edit-quotation",
    component: EditQuotation,
    meta: {
      middleware: AuthMiddleware,
      layout: "user",
      title: "Edit Quotation",
    },
  },
  {
    path: "/list-invoice",
    name: "list-invoice",
    component: ListInvoice,
    meta: { middleware: AuthMiddleware, layout: "user", title: "List Invoice" },
  },
  {
    path: "/add-invoice",
    name: "add-invoice",
    component: AddInvoice,
    meta: { middleware: AuthMiddleware, layout: "user", title: "Add Invoice" },
  },
  {
    path: "/edit-invoice/:id",
    name: "edit-invoice",
    component: EditInvoice,
    meta: { middleware: AuthMiddleware, layout: "user", title: "Edit Invoice" },
  },
  {
    path: "/show-invoice",
    name: "show-invoice",
    component: ShowInvoice,
    meta: {
      middleware: AuthMiddleware,
      layout: "user",
      title: "Invoice Details",
    },
  },
  {
    path: "/setting",
    name: "setting",
    component: SettingComponent,
    meta: { middleware: AuthMiddleware, layout: "user", title: "Setting" },
  },
  {
    path: "/stripe-success",
    name: "stripe-success",
    component: SuccessStripe,
    meta: {
      middleware: AuthMiddleware,
      layout: "user",
      title: "Stripe Success",
    },
  },
  {
    path: "/stripe-error",
    name: "stripe-error",
    component: ErrorStripe,
    meta: { middleware: AuthMiddleware, layout: "user", title: "Stripe Error" },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach((to, from, failure) => {
  nextTick(() => {
    document.title = to.meta.title
      ? `${to.meta.title.replace(/\b\w/g, (match) =>
          match.toUpperCase()
        )} | Smoothbalance`
      : "Smoothbalance";
  });
});

function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {  
  let routes = ["login", "register", "regional-settings", "home", "forgot-password"];
  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware)
      ? to.meta.middleware
      : [to.meta.middleware];

    const context = {
      from,
      next,
      router,
      to,
    };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  } else if (routes.includes(to.name)) {
    if (store.getters['auth/getLoggedIn']) {
      return next({ name: 'user-dashboard' })
    }
  }

  return next();
});


export default router;
