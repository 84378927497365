import { createStore } from "vuex";
import { auth } from "./auth.module";
import { register } from "./register.module";
import { company } from "./company.module";
import createPersistedState from "vuex-persistedstate";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { expenses } from "./expenses.module";
import { item } from "./item.module";
import { quotation } from "./quotation.module";
import { setting } from "./setting.module";
import { invoice } from "./invoice.module";
import { client } from "./client.module";

const secret_key = "smoothbalanceAES123";

const store = createStore({
  getters: {
    getSKey: () => {
      return secret_key;
    },
  },
  modules: {
    auth,
    register,
    company,
    expenses,
    item,
    quotation,
    setting,
    invoice,
    client,
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => {
          // console.log(`Getting item with key: ${key}`);
          let original_data = null;
          try {
            const cookieValue = Cookies.get(key);
            if (cookieValue) {
              var bytes = CryptoJS.AES.decrypt(cookieValue.toString(), secret_key);
              original_data = bytes.toString(CryptoJS.enc.Utf8);
              // console.log(`Decrypted data: ${original_data}`);
            }
          } catch (error) {
            console.log(error);
          }
          return original_data;
        },
        setItem: (key, value) => {
          // console.log(`Setting item with key: ${key} and value: ${value}`);
          const encrypted_value = CryptoJS.AES.encrypt(value.toString(), secret_key).toString();
          Cookies.set(key, encrypted_value, { expires: 100000, secure: true });
          // console.log(`Encrypted and set cookie: ${encrypted_value}`);
        },
        removeItem: (key) => {
          // console.log(`Removing item with key: ${key}`);
          Cookies.remove(key);
        },
      },
    }),
  ],
});

export default store;
