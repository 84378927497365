<template>
  <div>
    <section class="">
      <div class="bg-pattern overlay-signIn logReg-container">
        <div class="col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-4 mx-auto">
          <!--login form block start-->
          <div class="card card-form sign-upIn">
            <div class="card-header">
              <div class="card-title">Sign in</div>
            </div>
            <div class="card-body">
              <form @submit.prevent="recaptcha" class="base-form">
                <h6 class="form-title">User sign in details</h6>

                <div class="input-group _ig-transparent mb-3">
                  <span class="input-group-text"><i class="fa fa-user"></i> </span>
                  <input type="text" v-model="form.email" name="u_login" id="u_login" class="form-control"
                    placeholder="Enter your email" required />
                </div>

                <div class="input-group _ig-transparent mb-3">
                  <span class="input-group-text"><i class="fa fa-lock"></i> </span>
                  <input :type="showPassword ? 'text' : 'password'" v-model="form.password" ref="password"
                    autocomplete="on" name="u_password" id="u_password" class="form-control"
                    placeholder="Confirm password" required />
                  <span class="input-group-text" style="cursor: pointer" v-on:click="showPassword = !showPassword"
                    id="toggle_pwd"><i class="fa fa-eye-slash"></i>
                  </span>
                </div>
                <div class="mb-4 d-flex justify-content-end">
                  <router-link to="/forgot-password" :class="`small`">Forgot Password?</router-link>
                </div>
                <!-- <div class="mb-3">
                  <div class="form-check form-switch onOff">
                    <input class="form-check-input" type="checkbox" id="checkSwitch" v-model="form.rememberMe" />
                    <label class="form-check-label" for="checkSwitch">Remember Me</label>
                  </div>
                </div> -->

                <div class="mb-3 d-flex justify-content-end">
                  <button type="submit" name="signIn" class="btn btn-success" :disabled="loading">
                    Sign<span v-show="loading">ing</span> in
                    <div v-show="loading" class="spinner-border text-light"
                      style="height: 1rem; width: 1rem; margin-left: 7px" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </button>
                </div>

                <div class="form-footer _hasLine">
                  <p>
                    If you do not have an account, please click here to
                    <router-link to="/regional-settings">Register</router-link>
                  </p>
                  <router-link to="/regional-settings" :class="`btn btn-secondary btn-sm`">Sign up</router-link>
                </div>
              </form>
            </div>
          </div>
          <!--ended login form block-->
        </div>
      </div>
    </section>
  </div>
</template>
<script>
export default {
  name: "Login",
  created() {
    const user = JSON.parse(localStorage.getItem("user-details"));
    if (user) {
      this.form.email = user.email;
      this.form.password = user.password;
      this.form.rememberMe = true; // set the "remember me" checkbox to checked
    }
  },

  data() {
    return {
      loadingInstance: null,
      loading: false,
      showPassword: false,
      form: {
        email: "",
        password: "",
        rememberMe: false,
        recaptcha_token: ''
      },
    };
  },
  watch: {},
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    recaptcha() {
      this.$recaptchaLoaded().then(() => {
        this.$recaptcha("login").then((token) => {
          this.form.recaptcha_token = token;
          this.handleSubmit();
        });
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    handleSubmit() {
      this.showLoading();
      this.$store
        .dispatch("auth/login", this.form)
        .then((res) => {
          if (res.status == 1) {
            this.$toast.success("Welcome!");
            this.$router.push("dashboard");
          } else {
            this.$toast.error(res.message);
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data.message) {
            this.$toast.error(err.response.data.message);
            return;
          }
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>
