<template>
    <div>
        <!--header start-->
        <header class="header">
            <div class="container-fluid d-flex justify-content-center align-items-center h-100">
                <div class="brand-logo">
                    <div class="">
                        <!-- <img src="assets/images/system/smoothbalance_logo.svg"/> -->
                        <img src="@/assets/images/system/smoothbalance_logo.png" alt="my-logo" />
                    </div>
                    <h3 class="brand-name mb-2">
                        Smoothbalance
                    </h3>
                </div>
            </div>
        </header>
        <!--header end-->
        <router-view/>
    </div>
</template>
<script>
  // Please just use `@` to refer to the root "src" directory of the project
  import myLogoSrc from "@/assets/images/system/smoothbalance_logo.png";

  export default {
    name: "Auth",
    setup() {
      return {
        myLogoSrc
      };
    }
  };
</script>
<style scoped>
.scale-slide-enter-active,
.scale-slide-leave-active {
  position: absolute;
  transition: all 0.85s ease;
}


.scale-slide-enter-from {
  left: -100%;
}


.scale-slide-enter-to {
  left: 0%;
}


.scale-slide-leave-from {
  transform: scale(1);
}


.scale-slide-leave-to {
  transform: scale(0.8);
}
</style>