import {GET_INVOICE, GET_INVOICE_NUMBER, SAVE_INVOICE, GET_INVOICE_BY_NUMBER, DELETE_INVOICE ,SAVE_EDIT_INVOICE, VOID_INVOICE, GET_SEARCH_DATA, SAVE_INVOICE_ATTACHMENT} from '../config/url';
import axios from "axios";
import authHeader from './auth-header';

class InvoiceService {
    getList(invoice_data) {
        return axios.post(GET_INVOICE, invoice_data, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw error;
        });
    }
    searchList(invoice_data) {
        return axios.post(GET_SEARCH_DATA, invoice_data, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw error;
        });
    }

    getInvoiceNumber() {
        return axios.get(GET_INVOICE_NUMBER, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw error;
        });
    }

    saveInvoice(invoice_data) {
        return axios.post(SAVE_INVOICE, invoice_data, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw error;
        });
    }
    saveInvoiceAttachment(invoice_data) {
        return axios.post(SAVE_INVOICE_ATTACHMENT, invoice_data, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw error;
        });
    }

    saveEditInvoice(invoice_data) {
        return axios.post(SAVE_EDIT_INVOICE, invoice_data, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw error;
        });
    }

    getInvoiceByNumber(invoice_data) {
        return axios.post(GET_INVOICE_BY_NUMBER, invoice_data, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw error;
        });
    }

    deleteInvoice(invoice_data) {
        return axios.post(DELETE_INVOICE, invoice_data, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw error;
        });
    }
    voidInvoice(invoice_data) {
        return axios.post(VOID_INVOICE, invoice_data, {
            headers: authHeader()
        }).then((res) => {
            return res;
        }).catch(error => {
            throw error;
        });
    }
}

export default new InvoiceService();