<template>
  <div class="card card-form sign-upIn _company">
    <div class="card-header">
      <div class="card-title w-auto">Tax and Registration</div>
    </div>
    <div class="card-body h-auto">
      <form class="base-form h-100 d-flex justify-content-between flex-column">
        <div class="w-100">
          <div class="w-100 mb-3 pe-5 d-flex flex-row align-items-center">
            <div class="col-sm-7 px-4">Tax Details <i class="fa fa-edit ms-2"></i></div>
            <div class="col-sm-2 text-center">Rate</div>
          </div>

          <div class="w-100 d-flex align-items-center mb-3">
            <div class="d-flex flex-grow-1">
              <div class="input-group _group-xl">
                <span class="input-group-text _w-55 prepend">
                  <el-input
                    v-model="tax_1_title"
                    :disabled="!tax_1_status_enable"
                    placeholder="name"
                  />
                </span>
                <input
                  :min="1"
                  :max="100"
                  class="form-control text-center"
                  placeholder="%"
                  v-model="tax_1_rate"
                  :disabled="!tax_1_status_enable"
                />
                <span
                  class="input-group-text text-md has-leftBar append bg-white text-danger strong"
                  >%</span
                >
              </div>
            </div>

            <div class="d-flex flex-shrink-0 ms-2">
              <div class="form-check form-switch onOff">
                <el-switch
                  v-model="tax_1_status_enable"
                  style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                  active-text="On"
                  inactive-text="Off"
                  inline-prompt
                />
              </div>
            </div>
          </div>

          <div class="w-100 d-flex align-items-center mb-3">
            <div class="d-flex flex-grow-1">
              <div class="input-group _group-xl">
                <span class="input-group-text _w-55 prepend">
                  <el-input v-model="tax_2_title" :disabled="!tax_2_status_enable" />
                </span>
                <input
                  :min="1"
                  :max="100"
                  class="form-control text-center"
                  v-model="tax_2_rate"
                  :disabled="!tax_2_status_enable"
                />
                <span
                  class="input-group-text text-md has-leftBar append bg-white text-danger strong"
                  >%</span
                >
              </div>
            </div>

            <div class="d-flex flex-shrink-0 ms-2">
              <div class="form-check form-switch onOff">
                <el-switch
                  v-model="tax_2_status_enable"
                  style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949"
                  active-text="On"
                  inactive-text="Off"
                  inline-prompt
                />
              </div>
            </div>
          </div>
        </div>

        <div class="w-100 pt-5">
          <div class="card-tabular mt-5">
            <div class="card-header _blueLegon">Tax/Vat registration number</div>
            <div class="card-body _wildSand pt-1">
              <form class="base-form w-100">
                <input
                  v-model="tax_vat_number"
                  class="form-control transparent border-0"
                  placeholder="company tax/vat number"
                />
              </form>
            </div>
          </div>
          <div class="card-tabular mt-5">
            <div class="card-header _blueLegon">Registration information</div>
            <div class="card-body _wildSand pt-1">
              <form class="base-form w-100">
                <input
                  v-model="sole_trader_reg_number"
                  class="form-control transparent border-0"
                  placeholder="Compay or Business registration number or ID"
                />
              </form>
            </div>
          </div>
        </div>
        <div class="w-100 mt-3 px-4">
          <div class="form-text">
            e.g Co. House reg. no, TIN, PAN, GST, ITN EIN, SSN, EIN
          </div>
        </div>

        <div class="form-footer _mt-4rem d-flex align-items-end">
          <button type="button" role="button" class="btn btn-blueLegon" @click="saveData">
            Save
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "TaxAndRegistration",
  data() {
    return {
      loadingInstance: null,
      tax_1_title: "Tax-1",
      tax_1_rate: 0,
      tax_1_status_enable: false,
      tax_2_status_enable: false,
      tax_2_title: "Tax-2",
      tax_2_rate: 0,
      tax_vat_number: "",
      sole_trader_reg_number: "",
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    saveData() {
      this.showLoading();

      let form_data = new FormData();

      form_data.append("tax1_name", this.tax_1_title);
      form_data.append("tax1_rate", this.tax_1_rate);
      form_data.append("is_tax1_enable", !this.tax_1_status_enable ? 0 : 1);
      form_data.append("tax2_name", this.tax_2_title ? this.tax_2_title : "");
      form_data.append("tax2_rate", this.tax_2_rate ? this.tax_2_rate : 0);
      form_data.append("is_tax2_enable", !this.tax_2_status_enable ? 0 : 1);
      form_data.append("registration_number", this.sole_trader_reg_number);
      form_data.append("tax_number", this.tax_vat_number);

      this.$store
        .dispatch("setting/saveTaxDetails", form_data)
        .then((res) => {
          if (parseInt(res.data.status) == 1) {
            this.$toast.success("Save data Successful.");
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    getData() {
      this.showLoading();
      this.$store
        .dispatch("setting/getTaxDetails")
        .then((res) => {
          if (res.data.status == 1) {
            this.tax_1_title = res.data.response.tax1_name
              ? res.data.response.tax1_name
              : "";
            this.tax_1_rate = res.data.response.tax1_rate
              ? res.data.response.tax1_rate
              : 0;
            this.tax_1_status_enable = res.data.response.is_tax1_enable ? true : false;
            this.tax_2_title = res.data.response.tax2_name
              ? res.data.response.tax2_name
              : "";
            this.tax_2_rate = res.data.response.tax2_rate
              ? res.data.response.tax2_rate
              : 0;
            this.tax_2_status_enable = res.data.response.is_tax2_enable ? true : false;
            this.sole_trader_reg_number = res.data.response.registration_number
              ? res.data.response.registration_number
              : "";
            this.tax_vat_number = res.data.response.tax_number
              ? res.data.response.tax_number
              : 0;
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
  },
};
</script>

<style scoped>
table thead tr th {
  background-color: #204b80;
  color: aliceblue;
}
</style>
<style scoped></style>
