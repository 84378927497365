import {
  GET_ITEMS,
  GENERATE_ITEM_NUMBER,
  CREATE_ITEM,
  GET_CATEGORY_LIST,
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  DELETE_ITEM,
  UPDATE_ITEM,
  UPDATE_ADDED_ITEM_DETAILS,
  UPDATE_ADDED_ITEM,
  GET_QUOTATION_ITEM_DETAIL,
  UPDATE_ADDED_ITEM_QUOTATION,
} from "../config/url";
import axios from "axios";
import authHeader from "./auth-header";

class ItemService {
  getList() {
    return axios
      .post(GET_ITEMS, null, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  generateItemNumber() {
    return axios
      .get(GENERATE_ITEM_NUMBER, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
  createItem(item) {
    return axios
      .post(CREATE_ITEM, item, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
  updateAddedItem(item) {
    return axios
      .post(UPDATE_ADDED_ITEM, item, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
  updateAddedItemQuotation(item) {
    return axios
      .post(UPDATE_ADDED_ITEM_QUOTATION, item, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
  getAddedItem(item) {
    return axios
      .post(UPDATE_ADDED_ITEM_DETAILS, item, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
  getAddedItemQuotation(item) {
    return axios
      .post(GET_QUOTATION_ITEM_DETAIL, item, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
  updateItem(item) {
    return axios
      .post(UPDATE_ITEM, item, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
  deleteItem(item_id) {
    return axios
      .post(DELETE_ITEM, item_id, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  fetchCategory() {
    return axios
      .get(GET_CATEGORY_LIST, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }

  createCategory(itemCategory) {
    return axios
      .post(CREATE_CATEGORY, itemCategory, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
  deleteCategory(item_id) {
    return axios
      .post(DELETE_CATEGORY, item_id, {
        headers: authHeader(),
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        throw new Error(error);
      });
  }
}

export default new ItemService();
