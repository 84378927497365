import InvoiceService from "../services/invoice.service";

const initialState = {
    list_invoice: []
}

export const invoice = {
    namespaced: true,
    state: initialState,
    getters: {
        getInvoice: (state) => {
            return state.list_invoice;
        },
        getInvoiceEditData: ({ invoiceById }) => (id) => invoiceById[id]
    },
    actions: {
        getList({ commit }, invoiceData) {
            return InvoiceService.getList(invoiceData)
                .then((response) => {
                    // commit('listinvoice', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        searchList({ commit }, invoiceData) {
            return InvoiceService.searchList(invoiceData)
                .then((response) => {
                    // commit('listinvoice', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        getInvoiceNumber({ commit }, invoice) {
            return InvoiceService.getInvoiceNumber(invoice)
                .then((response) => {
                    // commit('listinvoice', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        saveInvoice({ commit }, invoice) {
            return InvoiceService.saveInvoice(invoice)
                .then((response) => {
                    // commit('listinvoice', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        saveInvoiceAttachment({ commit }, invoice) {
            return InvoiceService.saveInvoiceAttachment(invoice)
                .then((response) => {
                    // commit('listinvoice', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        saveEditInvoice({ commit }, invoice) {
            return InvoiceService.saveEditInvoice(invoice)
                .then((response) => {
                    // commit('listinvoice', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        getInvoiceByNumber({ commit }, invoice) {
            return InvoiceService.getInvoiceByNumber(invoice)
                .then((response) => {
                    // commit('listinvoice', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        deleteInvoice({ commit }, invoice) {
            return InvoiceService.deleteInvoice(invoice)
                .then((response) => {
                    // commit('listinvoice', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        voidInvoice({ commit }, invoice) {
            return InvoiceService.voidInvoice(invoice)
                .then((response) => {
                    // commit('listinvoice', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
    },
    mutations: {
        save(state, invoice) {
            Object.assign(state, invoice);
        },
        listInvoice(state, invoice) {
            state.list_invoice = invoice.data.response;
            state.invoiceById = invoice.data.response.reduce((acc, invoice) => {
                acc[invoice.id] = invoice;
                return acc;
            }, {});
            localStorage.setItem('list-invoice', JSON.stringify(invoice.data.response));
        },
    }
}