<template>
  <div class="">
    <div class="-content d-flex align-items-start flex-column">
      <div class="page-global-header px-0">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 offset-xl-3 offset-xxl-3">
            <div class="global-searchbar laurenGreen">
              <form class="base-form">
                <div class="input-group">
                  <div class="input-group-text">
                    <img src="@/assets/images/system/tools.svg" />
                  </div>
                  <input type="search" name="globalSearch" class="form-control"
                    placeholder="Search (Company name, Client name, Email etc)" />
                  <button type="submit" name="btnSearch" class="btn btn-lauren-green">
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </form>
            </div>
          </div>
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-3 col-xxl-3 d-flex align-items-center justify-content-end">
            <ul class="search-option-menu me-3">
              <li class="nav-item me-2">
                <a href="#" class="nav-link circleNav" @click="dialogVisibleCategoryPopup = true">
                  <img src="@/assets/images/system/network-group.png" />
                </a>
              </li>
              <li class="nav-item">
                <div class="form-check outliner">
                  <input class="form-check-input" type="checkbox" @change="showAddItemCheckbox"
                    v-model="showHideCheckboxToAddToQuotation" />
                </div>
              </li>
              <li class="nav-item sorting">
                <input type="checkbox" class="sorting-check" id="btn-sorting" autocomplete="off" />
                <label class="btn btn-sorting" for="btn-sorting"><span class="sr-only">Sort</span>
                </label>
              </li>
              <li class="nav-item">
                <router-link class="nav-link circleNav" :to="{ name: 'add-item' }">
                  <i class="fa fa-plus"></i>
                </router-link>
              </li>
              <li class="nav-item dropdown">
                <a href="#" class="nav-link dropdown-toggle no-caret" id="optionMore" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false"><i class="fa fa-ellipsis-v"></i>
                </a>
                <ul class="dropdown-menu dropdown-menu-end _v1" aria-labelledby="optionMore">
                  <li>
                    <a class="dropdown-item" href="#">Email CSV/PDF/Zip<i class="fa fa-envelope append"></i>
                    </a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">Analytics <i class="fas fa-chart-line append"></i>
                    </a>
                  </li>

                  <li>
                    <a class="dropdown-item" href="#">Print <i class="fa fa-print append"></i></a>
                  </li>
                  <li>
                    <a class="dropdown-item" href="#">Download PDF/CSV <i class="fa fa-download append"></i>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12 col-xl-6 col-xxl-6 offset-xl-3 offset-xxl-3 mt-3">
            <div class="global-filter-form">
              <form class="base-form form-xl d-flex flex-row">
                <select class="form-select _3d-input">
                  <option selected disabled>Select item category</option>
                  <option value="1">Category item one</option>
                  <option value="2">Category item two</option>
                  <option value="3">Category item three</option>
                </select>
                <button type="button" class="btn btn-white _3d-input _sq">
                  <i class="fa fa-cog"></i>
                </button>
                <button type="button" class="btn btn-white _3d-input _sq">
                  <i class="fas fa-running"></i>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="page-body">
        <div class="row justify-content-center">
          <!--repeatable block-->
          <div class="col-sm-7 mt-2" v-for="(item, index) in item_server_data" :key="index">
            <div class="card card-horizontal-list laurenGreen">
              <div class="card-body">
                <div class="hover-slidess">
                  <div class="form-check outliner">
                    <input class="form-check-input" type="checkbox" @change="addToRecentItems(item.item_no)"
                      v-if="showHideCheckbox" />
                  </div>
                </div>
                <div class="card-content">
                  <span class="card-count">{{ index + 1 }}.</span>
                  <ul class="label-list">
                    <li class="list-title">
                      <label>C:</label><span class="list-text">{{ item.category_type }}</span>
                    </li>
                    <li class="sub-title">
                      <label>I:</label><span class="list-text">{{ item.name }}</span>
                    </li>
                    <li>
                      <label>I.No:</label><span class="list-text">{{ item.item_no }}</span>
                    </li>
                    <li class="mt-2">
                      <label>D:</label><span class="list-text">{{ item.description }}</span>
                    </li>
                  </ul>
                </div>
                <div class="content-option">
                  <ul class="cmn-vrList">
                    <li>
                      <a href="#"><i class="fa fa-cog"></i> </a>
                    </li>
                    <li>
                      <a href="#"><i class="fas fa-running"></i> </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-footer has-action">
                <div class="ellipsis-dropdown dropdown">
                  <button class="btn dropdown-toggle no-caret" type="button" id="card-dropAction-1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="fas fa-ellipsis-v"></i>
                  </button>
                  <ul class="dropdown-menu" aria-labelledby="card-dropAction-1">
                    <li>
                      <a class="dropdown-item edit" :href="'/edit-item/'+item.id"><i class="fa fa-edit"></i>
                      </a>
                    </li>
                    <li>
                      <a class="dropdown-item delete" @click="deleteItem(item.id, item.item_no)" href="#"><i class="fa fa-times"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <div class="footer-list-wraper">
                  <ul class="footer-list">
                    <li>Quantity</li>
                    <li class="count">{{ item.quantity }}</li>
                  </ul>

                  <ul class="footer-list highlight">
                    <li>Rate</li>
                    <li class="count">&pound;{{ item.unit_price }}</li>
                  </ul>

                  <ul class="footer-list _big">
                    <li>Price</li>
                    <li class="count">&pound;{{ item.unit_price * item.quantity }}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <!--ended repeatable block-->
        </div>

      </div>
    </div>
  

  <el-dialog v-model="dialogVisibleCategoryPopup" title="Category" width="40%">
    <addCategory></addCategory>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisibleCategoryPopup = false">Close</el-button>
      </span>
    </template>
  </el-dialog>
</div>
</template>
<script>
import AddCategory from "./Category.vue";

export default {
  name: "ItemList",
  components: { AddCategory },
  data() {
    return {
      loadingInstance: null,
      item_server_data: [],
      dialogVisibleCategoryPopup: false,
      type: '',
      categories: [],
      showHideCheckboxToAddToQuotation: false,
      showHideCheckbox: false,
      addItemToRecentList: []
    };
  },
  created() {
    this.runMountedFunctions();
  },
  methods: {
    runMountedFunctions() {
      this.$store.dispatch('item/fetchCategory').then((res) => {
        this.categories = res;
        this.getData();
      });
    },
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: true
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    getData() {
      this.showLoading();
      this.$store
        .dispatch("item/getList", this.form)
        .then((res) => {
          if (res.status == 1) {
            this.item_server_data = res.response;
            this.$toast.success("Fetch data Successful.");
            // this.$router.push({ name: 'user-dashboard' });
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.item_server_data = [];
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    deleteItem(item_id, item_no='') {
      this.showLoading();
      this.removeItemFromRecentList(item_no);
      this.$store
        .dispatch("item/deleteItem", { 'item_id': item_id })
        .then((res) => {
          if (res.status == 1) {
            this.$toast.success("Delete data Successful.");
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.getData();
        });
    },
    showAddItemCheckbox() {
      if (this.showHideCheckboxToAddToQuotation) {
        this.showHideCheckbox = true;
      } else {
        this.showHideCheckbox = false;
      }
    },
    addToRecentItems(item_no) {
      this.$store.dispatch('item/getList', item_no).then(() => {
        this.$store.commit('item/addToRecentItems', item_no);
      });
    },
    removeItemFromRecentList(itemNoToRemove) {
      this.$store.commit('item/removeItemFromRecentList', itemNoToRemove);
    }
  },
};
</script>

<style scoped>
.hover-slidess {
  position: relative;
  top: 1rem;
  left: 1rem;
}
</style>