<template>
  <component :is="layout">
  </component>
</template>

<script>
import LoginLayout from "./components/layouts/auth";
import UserDashboardLayout from "./components/layouts/userDashboard";

export default {
  name: 'App',
  components: {
    LoginLayout,
    UserDashboardLayout
  },
  computed: {
    layout () {
      switch (this.$route.meta.layout) {
        case "login":
          return LoginLayout;
        case "user":
          return UserDashboardLayout;
        default:
          return LoginLayout;
      }
    }
  }
}
</script>
<style lang="scss">
</style>
<style scoped>
/* @import './assets/css/sb_masterLayout.css'; */
/* @import './assets/bootstrap/css/bootstrap.css'; */
@import './assets/fontawesome/css/all.css';
</style>
