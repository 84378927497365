import SettingService from "../services/setting.service";

const initialState = {
    list_setting: [],
    tax_details: []
}

export const setting = {
    namespaced: true,
    state: initialState,
    getters: {
        getSetting: (state) => {
            return state.list_setting;
        },
        getTaxDetails: (state) => {
            return state.tax_details;
        }
    },
    actions: {
        getList({ commit }, setting) {
            return SettingService.getList(setting)
                .then((response) => {
                    commit('listSubscription', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        getSubscriptionList({ commit }, setting) {
            return SettingService.getSubscriptionList(setting)
                .then((response) => {
                    // commit('listSubscription', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        getSubscriptionCheckout({ commit }, setting) {
            return SettingService.getSubscriptionCheckout(setting)
                .then((response) => {
                    // commit('listSubscription', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        successHandler({ commit }, setting) {
            return SettingService.successHandler(setting)
                .then((response) => {
                    // commit('listSubscription', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        getTaxDetails({ commit }, tax_details) {
            return SettingService.getTaxDetails(tax_details)
                .then((response) => {
                    commit('taxDetails', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        saveTaxDetails({ commit }, tax_details) {
            return SettingService.saveTax(tax_details)
                .then((response) => {
                    // console.log(commit);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },

        getBankDetails({ commit }, tax_details) {
            return SettingService.getBankDetails(tax_details)
                .then((response) => {
                    commit('bankDetails', response);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
        saveBankDetails({ commit }, bank_details) {
            return SettingService.saveBankDetails(bank_details)
                .then((response) => {
                    console.log(commit);
                    return response;
                })
                .catch((error) => {
                    return Promise.reject(error);
                });
        },
    },
    mutations: {
        save(state, setting) {
            Object.assign(state, setting);
        },
        listSubscription(state, setting) {
            state.list_setting = setting.data.response;
            // localStorage.setItem('list-setting', JSON.stringify(setting.data.response));
        },

        taxDetails(state, tax_details) {
            state.tax_details = tax_details.data.response;
            // localStorage.setItem('tax-details', JSON.stringify(tax_details.data.response));
        },

        bankDetails(state, bank_details) {
            state.bank_details = bank_details.data.response;
            // localStorage.setItem('bank-details', JSON.stringify(bank_details.data.response));
        },
    }
}