<template>
    <form class="form-v2" @submit.prevent="addCategory">
        <div class="row m-0 inline-fields mb-3">
            <div class="input-group flex-grow-1 w-auto p-0">
                <div class="input-group-text prepend"><img src="@/assets/images/system/network_laurenGreen.png" /></div>
                <input type="text" v-model="form.type" class="form-control" placeholder="Type category name" />
            </div>
            <button type="submit" name="btn" class="btn btn-success w-auto">Save</button>

        </div>

        <div class="row d-flex align-items-center justify-content-center m-0 mb-2">
            <button type="button" name="toggleDrop" class="btn btn-toggleDrop" data-bs-toggle="collapse"
                data-bs-target="#formMore" aria-expanded="true" aria-controls="formMore">
                <span><i class="fas fa-chevron-down"></i> </span>
                <span><i class="fas fa-chevron-down"></i> </span>
            </button>
        </div>

        <div class="collapse show" id="formMore">
            <h6>Existing Categories</h6>

            <ul class="list-group _embossed mt-3">
                <li class="list-group-item" v-for="(category, index) in categories" :key="category.id">
                    <label class="item-label"><span class="list-count">{{ index + 1 }}</span> {{ category.type }}</label>
                    <button type="button" name="remove" class="btn btn-remove" @click="deleteCategory(category.id)"><i
                            class="fa fa-times"></i> </button>
                </li>
            </ul>
        </div>
    </form>
</template>

<script>

export default {
    name: "ItemList",

    data() {
        return {
            loadingInstance: null,
            form: {
                type: "",
            },
            type: '',
            categories: []
        };
    },
    created() {
        this.runMountedFunctions();
    },
    methods: {
        runMountedFunctions() {
            this.$store.dispatch('item/fetchCategory').then((res) => {
                this.categories = res;
            });
        },
        showLoading() {
            this.loadingInstance = this.$loading({
                lock: true,
                text: 'Loading',
                background: 'rgba(0, 0, 0, 0.7)',
                fullscreen: true
            });
        },
        hideLoading() {
            this.loadingInstance.close();
        },
        addCategory() {
            this.$store
                .dispatch("item/createCategory", this.form)
                .then((res) => {
                    this.form.type = '';
                    if (res.status == 1) {
                        this.$store.dispatch('item/fetchCategory').then((res) => {
                            this.categories = res;
                        });
                        this.$toast.success("Create category successful.");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.$toast.error("Something went wrong. Please try again.");
                })
                .finally(() => {
                    this.hideLoading();
                });
        },
        deleteCategory(category_id) {
            this.$store
                .dispatch("item/deleteCategory", { 'category_id': category_id })
                .then((res) => {
                    console.log(res)
                    if (res.status == 1) {
                        this.$store.dispatch('item/fetchCategory').then((res) => {
                            this.categories = res;
                        });
                        this.$toast.success("Delete category successful.");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    this.$toast.error("Something went wrong. Please try again.");
                })
                .finally(() => {
                    this.hideLoading();
                });
        }
    },
};
</script>