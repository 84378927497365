<template>
  <div>
    <div class="card card-general">
      <div class="card-body">
        <!-- <div class="d-flex justify-content-end mb-2">
          <button class="btn btn-white">Item No: {{ item_form.item_no }}</button>
        </div> -->
        <form class="form-v2 h-100 d-flex flex-column justify-content-between">
          <div class="w-100">
            <div class="row mb-2">
              <div class="form-check form-switch col-6">
                <input
                  class="form-check-input parts"
                  type="radio"
                  id="parts"
                  value="parts"
                  v-model="item_form.service_type"
                  disabled="disabled"
                />
                <label
                  class="form-check-label"
                  :for="item_form.service_type === 'parts' ? 'parts' : 'services'"
                  >Parts</label
                >
              </div>
              <div class="form-check form-switch ms-5 col-6">
                <input
                  class="form-check-input services"
                  type="radio"
                  id="services"
                  value="services"
                  v-model="item_form.service_type"
                  disabled="disabled"
                />
                <label
                  class="form-check-label"
                  :for="item_form.service_type === 'services' ? 'services' : 'parts'"
                  >Services</label
                >
              </div>
            </div>
            <div class="row mb-3">
              <div class="input-group p-0">
                <div class="input-group-text prepend">
                  <img src="@/assets/images/system/tools_lauren-green.svg" />
                </div>
                <input
                  type="text"
                  name="itemTitle"
                  class="form-control"
                  v-model="item_form.name"
                  placeholder="Type item name or title*"
                  disabled="disabled"
                />
              </div>
            </div>

            <div class="row mb-4">
              <textarea
                class="form-control"
                v-model="item_form.description"
                placeholder="Parts or Services description"
              ></textarea>
            </div>
          </div>

          <div class="w-100">
            <div class="row m-0 mb-3 d-flex justify-content-end">
              <div class="col-sm-9">
                <div class="row mb-3">
                  <div class="input-group group-half p-0">
                    <div class="input-group-text bg-light-orange prepend no-after">
                      Quantity
                    </div>
                    <div class="form-floating">
                      <label class="floating-label _sm _end">(default=1)</label>
                      <input
                        type="number"
                        class="form-control text-right"
                        @keyup="calculateAmount"
                        v-model.number="item_form.quantity"
                        min="1"
                      />
                    </div>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="input-group group-half p-0">
                    <div class="input-group-text bg-light-sky no-after prepend">Rate</div>
                    <div class="form-floating">
                      <label class="floating-label">&pound;</label>
                      <input
                        type="number"
                        class="form-control text-right"
                        v-model.number="item_form.unit_price"
                        @keyup="calculateAmount"
                        step="0.01"
                        min="0.00"
                      />
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="input-group group-half p-0">
                    <div class="input-group-text bg-orange no-after prepend">Amount</div>
                    <div class="form-floating">
                      <label class="floating-label">&pound;</label>
                      <input
                        type="number"
                        class="form-control text-right"
                        v-model="item_form.amount"
                        step="0.01"
                        min="0.00"
                        disabled="disabled"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="card-footer">
        <div class="row m-0 d-flex justify-content-end">
          <div class="cmn-aw p-0">
            <button
              type="submit"
              name="save"
              class="btn btn-success btn-big _3d-input"
              @click="updateItem"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>

    <el-dialog v-model="dialogVisibleCategoryPopup" title="Category" width="40%">
      <addCategory></addCategory>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisibleCategoryPopup = false">Close</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import AddCategory from "./Category.vue";

export default {
  name: "EditItemQuotation",
  components: { AddCategory },
  props: ["quotationId", "itemId"],
  data() {
    return {
      item_form: {
        service_type: "parts",
        name: "",
        description: "",
        quantity: 1,
        unit_price: 0,
        amount: 0,
        quotation_id: "",
        category_id: "",
        item_id: 0,
      },
      category: "",
      dialogVisibleCategoryPopup: false,
    };
  },
  created() {
    this.fetchCategory();
    this.getItemEditData();
  },
  computed: {
    categoryOptions() {
      if (this.category) {
        return this.category.map((v) => {
          return {
            id: v.id,
            type: v.type,
          };
        });
      }
      return [];
    },
  },
  methods: {
    showLoading() {
      this.loadingInstance = this.$loading({
        lock: true,
        text: "Loading",
        background: "rgba(0, 0, 0, 0.7)",
        fullscreen: true,
      });
    },
    hideLoading() {
      this.loadingInstance.close();
    },
    calculateAmount() {
      this.item_form.amount =
        this.item_form.quantity * parseFloat(this.item_form.unit_price).toFixed(2);
    },
    getItemEditData() {
      this.showLoading();

      this.$store
        .dispatch("item/getAddedItemQuotation", {
          quotation_id: this.quotationId,
          item_id: this.itemId,
        })
        .then((res) => {
          this.item_form = {
            service_type: res.response.type,
            name: res.response.name,
            description: res.response.description,
            quantity: res.response.quantity,
            unit_price: res.response.unit_price,
            amount: res.response.quantity * res.response.unit_price,
            item_id: res.response.item_id,
            category_id: res.response.category_id,
          };
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    updateItem() {
      this.showLoading();

      const item_form_data = {
        name: this.item_form.name,
        description: this.item_form.description,
        quantity: this.item_form.quantity,
        unit_price: parseFloat(this.item_form.unit_price),
        item_id: this.item_form.item_id,
        quotation_id: this.quotationId,
      };
      this.$store
        .dispatch("item/updateAddedItemQuotation", item_form_data)
        .then((res) => {
          if (res.status == 1) {
            this.$toast.success("Update data Successful.");
          } else {
            throw new Error(res);
          }
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    resetForm() {
      this.item_form = {
        service_type: "parts",
        name: "",
        description: "",
        quantity: 1,
        unit_price: 0,
        amount: 0,
        item_no: "",
        category_id: "",
      };
    },
    fetchCategory() {
      this.$store
        .dispatch("item/fetchCategory", this.item_form)
        .then((res) => {
          this.category = res;
        })
        .catch((err) => {
          console.log(err);
          this.$toast.error("Something went wrong. Please try again.");
        })
        .finally(() => {});
    },
    setAddedItemNumber() {
      this.listAddedItemNumber =
        JSON.parse(localStorage.getItem("item_recently_added_items")) || [];
    },
  },
  watch: {
    itemId(newVal) {
      if (newVal != 0) {
        this.getItemEditData();
      }
    },
  },
};
</script>
